const cookies = require("js-cookie");
import _ from "lodash/object";

const plugin = {
  that: this,
  defaultConfig: {
    expires: Number(config.cookie.expires),
    path: config.cookie.path,
    SameSite: "None",
    Secure: true,
  },
  install(Vue, options = {}) {
    plugin.defaultConfig = _.merge(plugin.defaultConfig, options);

    let injectedCookies = {};

    let { set, remove, defaultConfig } = plugin;

    let getUniversal = (key, defaultValue = null) => {
      if (typeof injectedCookies[key] === "undefined") {
        return plugin.get(key, defaultValue);
      }

      let val = plugin.get(key, defaultValue);
      let injectedVal = injectedCookies[key];

      // if the value is null or default, and the injected value is not - use the indected value
      if (val === null || (val === defaultValue && injectedVal !== defaultConfig)) {
        return injectedVal;
      }

      return defaultValue;
    };

    let injectServerCookies = (cookies) => {
      if (!cookies || typeof cookies !== "object") {
        return true;
      }

      injectedCookies = { ...cookies };
    };

    Vue.config.globalProperties.$cookie = {
      set,
      get: getUniversal,
      remove,
      defaultConfig,
      injectServerCookies,
    };
    Vue.injectServerCookies = injectServerCookies;
  },
  set(key, value, config = plugin.defaultConfig) {
    return cookies.set(key, value, config);
  },
  get(key, defaultValue = null) {
    let val = cookies.get(key);
    if (typeof val === "undefined") {
      return defaultValue;
    }

    if (typeof val === "string") {
      try {
        let result = JSON.parse(val);
        if (result) {
          return result;
        }
      } catch (e) {}
    }

    return val;
  },
  remove(name) {
    return cookies.remove(name);
  },
  delete(name) {
    return cookies.remove(name);
  },
};

export default {
  install: plugin.install,
};
